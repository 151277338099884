import React, { Component } from "react";
import { graphql, navigate } from "gatsby";
import Img from "gatsby-image";
import { connect } from "react-redux";
import { Checkbox } from "@instructure/ui-forms";
import { FormFieldGroup } from "@instructure/ui-form-field";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import IconCheckDark from "@instructure/ui-icons/lib/IconCheckDarkSolid";

import Layout from "../../../components/layout";
import QuestionHeader from "../../../components/question-header";
import SEO from "../../../components/seo";
import styles from "./index.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";
import { func, shape, object } from "prop-types";

class NormalcyModule extends Component {
  state = {
    checkedBoxes: [],
    correctAnswers: [],
    wrongAnswers: [],
    hasSubmitted: false,
    hasSeenFeedback: false
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    data: shape({
      file: shape({
        childImageSharp: shape({
          fixed: object
        })
      })
    })
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(1);
  }

  onSubmit = () => {
    if (!this.state.hasSubmitted && !this.hasSeenFeedback) {
      this.setState({
        hasSubmitted: true
      });
    } else if (this.state.hasSubmitted && !this.state.hasSeenFeedback) {
      this.setState({
        hasSeenFeedback: true
      });
    } else {
      this.props.goToStep(2, true);
    }
  };

  goBack = () => {
    if (this.state.hasSubmitted && this.state.hasSeenFeedback) {
      this.setState({
        hasSeenFeedback: false,
        hasSubmitted: true
      });
    } else if (this.state.hasSubmitted && !this.hasSeenFeedback) {
      this.setState({
        hasSubmitted: false
      });
    }
  };

  updateValue = newVal => {
    if (this.state.checkedBoxes.includes(newVal)) {
      this.setState({
        checkedBoxes: this.state.checkedBoxes.filter(x => x !== newVal)
      });
    } else {
      this.setState({
        checkedBoxes: this.state.checkedBoxes.concat([newVal])
      });
    }
  };

  renderFeedback(value) {
    if (!this.state.hasSubmitted) {
      return null;
    }
    if (value !== "birth") {
      return (
        <span className={`${styles.feedback} ${styles.feedbackPositive}`}>
          <IconCheckDark />
        </span>
      );
    } else {
      return <span className={`${styles.feedback} ${styles.feedbackEmpty}`} />;
    }
  }

  render() {
    const { data } = this.props;
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          {!this.state.hasSeenFeedback && (
            <div className={styles.photoArea}>
              <Img fixed={data.cheerleader.childImageSharp.fixed} />
            </div>
          )}
          {!this.state.hasSeenFeedback && (
            <div className={styles.textPromptArea}>
              <div className={styles.textBlurb}>
                <p>
                  <b>A few years ago</b>, a girl in foster care was a high
                  school cheerleader. A team at her school had advanced to the
                  state tournament. The first game was in two days, and the
                  entire cheer squad was traveling to the game to support the
                  team. Of course, she wanted to go too.
                </p>
              </div>
            </div>
          )}

          <div
            className={
              styles[
                this.state.hasSeenFeedback
                  ? "hasSeenFeedbackArea"
                  : "questionArea"
              ]
            }
          >
            {this.state.hasSeenFeedback ? (
              <>
                <div>
                  Fast forward to TODAY. <b>Today</b> that girl needs permission
                  from just one person: <b>Her foster parent</b>.<br />
                  <br />
                  <Img
                    className={styles.hasSeenFeedbackGraphic}
                    alt="Checkboxes with foster parent permission checked"
                    fluid={data.onlyfoster.childImageSharp.fluid}
                  />
                  So what changed? Let's find out.
                </div>
              </>
            ) : (
              <FormFieldGroup
                description={
                  <QuestionHeader
                    icon={
                      <span
                        className={styles.emoji}
                        role="img"
                        aria-label="Thinking Face"
                      >
                        🤔
                      </span>
                    }
                  >
                    <span>
                      Whose permission did the girl need? Mark as many as you
                      think.
                    </span>
                  </QuestionHeader>
                }
              >
                <div className={styles.checkboxWrapper}>
                  {this.renderFeedback("birth")}
                  <Checkbox
                    label={<span>Birth parent permission</span>}
                    value="birth"
                    checked={this.state.checkedBoxes.includes("birth")}
                    onChange={event => this.updateValue(event.target.value)}
                  />
                </div>
                <div className={styles.checkboxWrapper}>
                  {this.renderFeedback("foster")}
                  <Checkbox
                    label="Foster parent permission"
                    value="foster"
                    checked={this.state.checkedBoxes.includes("foster")}
                    onChange={event => this.updateValue(event.target.value)}
                  />
                </div>
                <div className={styles.checkboxWrapper}>
                  {this.renderFeedback("caseworker")}
                  <Checkbox
                    label="Caseworker approval"
                    value="caseworker"
                    checked={this.state.checkedBoxes.includes("caseworker")}
                    onChange={event => this.updateValue(event.target.value)}
                  />
                </div>
                <div className={styles.checkboxWrapper}>
                  {this.renderFeedback("caseworker_supervisor")}
                  <Checkbox
                    label="Caseworker supervisor approval"
                    value="caseworker_supervisor"
                    checked={this.state.checkedBoxes.includes(
                      "caseworker_supervisor"
                    )}
                    onChange={event => this.updateValue(event.target.value)}
                  />
                </div>
              </FormFieldGroup>
            )}
          </div>
          <div className={styles.feedbackArea}>
            {this.state.hasSubmitted && !this.state.hasSeenFeedback && (
              <div className={styles.feedbackBlurb}>
                <b>A few years ago</b>, she needed everything except birth
                parent permission. That was a lot of people involved in a
                routine parental decision. It took time and effort to track
                everyone down, and sometimes it meant kids missed out on
                activities.
              </div>
            )}
          </div>
          {this.state.hasSubmitted && (
            <div className={styles.backButtonArea}>
              <Button
                onClick={this.goBack}
                variant="success"
                icon={IconArrowOpenStart}
              />
            </div>
          )}

          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    cheerleader: file(relativePath: { eq: "cheerleader.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    onlyfoster: file(relativePath: { eq: "onlyfoster.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, shouldNavigate = false) {
      dispatch(setCurrentStep(stepId));
      if (shouldNavigate) {
        navigate("/modules/normalcy/what-is-normalcy");
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(NormalcyModule);
